import { defineStore } from 'pinia'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: null,
    team: null,
    selectedPeriod: null,
    selectedTeam: null,
    accessPages: null,
    editPages: null,
    accessDashboard : null,
    roleHighlight: null,
  })
})

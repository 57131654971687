<template>
  <div class="px-3 d-flex flex-row align-center justify-space-between" v-if="profileStore && profileStore.profile" style="width: 100%;">
    <div class="d-flex flex-row align-center justify-space-between">
      <v-img height="50" width="50" :src="require('@/assets/images/Logo.svg')"></v-img>
      <span class="mx-2" v-if="$vuetify.display.smAndUp">My first Business<span v-if="$vuetify.display.mdAndUp"> - V {{ version }}</span></span>
      <v-chip size="small" :color="realeaseData.color" class="mx-2" v-if="$vuetify.display.smAndUp && realeaseData.display">{{ realeaseData.text }}</v-chip>
    </div>
    <v-spacer />
    <div class="d-flex flex-column align-center" v-if="currentCountdown">
      <b class="text-primary">{{ currentCountdown.name }}</b>
      <div>{{ currentTime }}</div>
    </div>
    <v-spacer />
    <div class="d-flex flex-row align-center justify-space-between">
      <Avatar :profile="profileStore.profile" size="40" badge></Avatar>
      <div class="mx-2 d-flex flex-column" v-if="$vuetify.display.smAndUp">
        <span class="text-accent text-caption">{{ profileStore.profile.lastname }}</span>
        <span class="text-accent text-caption">{{ profileStore.profile.firstname }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/appdata.js'
import packageJSON from '@/../package.json'

import Avatar from '@/components/users/Avatar.vue'
import TeamLogo from '@/components/TeamLogo.vue'

export default {
  components: { Avatar, TeamLogo },
  data: () => ({
    profileStore: useProfileStore(),
    appStore: useAppStore(),
    version: packageJSON.version,
    realeaseData: packageJSON.realeaseData,

    now: new Date(),
    interval: undefined
  }),
  created() {
    clearInterval(this.interval)

    const tmp = new Date(this.now)
    tmp.setMilliseconds(0)
    tmp.setSeconds(0)
    tmp.setMinutes(this.now.getMinutes() + 1)
    const timeBeforeNextMinutes = tmp.getTime() - this.now.getTime()

    setTimeout(() => {
      this.interval = setInterval(() => {
        this.now = new Date()
      }, 1000 * 60)
    }, timeBeforeNextMinutes)
  },
  computed: {
    currentCountdown() {
      if (this.appStore.appdata.parameters.countdowns && this.appStore.appdata.parameters.countdowns.filter(c => c.enabled).length > 0) {
        return this.appStore.appdata.parameters.countdowns.filter(c => c.enabled)[0]
      }
    },
    currentTime() {
      if (this.currentCountdown) {
        let prefix = moment(this.now).diff(moment(new Date(this.currentCountdown.anchor))) > 0 ? 'Il y a ' : 'Dans '

        let duration = moment.duration(Math.abs(moment(this.now).diff(moment(new Date(this.currentCountdown.anchor)))))
        let days = duration.days().toFixed(0)
        let hours = duration.hours().toFixed(0)
        let minutes = duration.minutes()

        let timeStr = prefix 
        
        if(days > 0) timeStr += days + " jours "
        if(days > 0 || hours > 0) timeStr += hours.toString().padStart(2, '0') + ' h '
        if(days > 0 || hours > 0 || minutes > 0) timeStr += minutes.toString().padStart(2, '0') + ' min'

        return timeStr
      }
    }
  }
}

</script>
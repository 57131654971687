<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-img v-bind="props" cover :src=logoUrl style="aspect-ratio: 1/1;" :elevation="elevation ? elevation : 0" :width="width ? width : null" :height="height ? height : null" >
        <v-fade-transition>
          <div v-if="isHovering" style="height: 100%;">
            <slot name="hover"></slot>
          </div>
        </v-fade-transition>
      </v-img>
    </template>
  </v-hover>
</template>

<script>
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

export default {
  props: ['logo', 'elevation', 'width', 'height'],
  data() {
    return {
      logoUrl: null
    }
  },
  created() {
    if (this.logo) {
      const fileRef = ref(getStorage(), this.logo)
      getDownloadURL(fileRef).then(url => {
        this.logoUrl = url
      })
    } else {
      this.logoUrl = require('@/assets/images/Company.svg')
    }
  }
}
</script>

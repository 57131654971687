import firebaseConfig from '@/config/firebase'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import p5vue from 'p5vue'

import '@/styles/styles.scss'
import '@/styles/swal.scss'

loadFonts()
const pinia = createPinia()

createApp(App)
	.use(pinia)
	.use(router)
	.use(vuetify)
	.use(p5vue)
	.mount('#app')

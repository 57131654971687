<template>
  <v-footer app class="custom-grid text-caption text-secondary">
    <i class="text-start">Copyright ©{{ new Date().getFullYear() }} Loesus Pedagogia</i>
    <v-spacer />
    <span class="text-right" v-if="profileStore.profile && appStore.app && profileStore.profile.dev">
      <span>app.name&nbsp;:&nbsp;{{ appStore.app.name }}</span>
      &nbsp;|&nbsp;
      <span>app.id&nbsp;:&nbsp;{{ appStore.app.id }}</span>
      &nbsp;|&nbsp;
      <span>appdata.id&nbsp;:&nbsp;{{ appStore.appdata.id}}</span>
    </span>
  </v-footer>
</template>

<script>
import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/appdata.js'

export default {
  data() {
    return {
      profileStore: useProfileStore(),
      appStore: useAppStore()
    }
  }
}
</script>

<style scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
</style>

import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"

const db = getFirestore()
const collectionName = 'appdata/'

function docToInstance(document) {
  const data = document.data()
  return !data ? null : new Appdata(document.id, data.application, data.parameters)
}

export default class Appdata {
  constructor(id, application, parameters) {
    this.id = id
    this.application = application
    this.parameters = parameters
  }

  static listenByApplication(appId, callback) {
    const docQuery = query(collection(db, collectionName), where('application', '==', appId))

    return onSnapshot(docQuery, snapshot => {
      let appdata = null
      snapshot.forEach(document => {
        if (appdata === null) {
          appdata = docToInstance(document)
        } else {
          docToInstance(document).delete()
        }
      })

      callback(appdata)
    })
  }
  
  static listenAll(callback) {
    return onSnapshot(collection(db, collectionName), snapshot => {
      const list = []
      snapshot.forEach(document => {
        let res = docToInstance(document)
        list.push(res)
      })
      callback(list)
    })
  }

  async save() {
    const new_appdata = {
      parameters: this.parameters
    }

    await updateDoc(doc(db, collectionName, this.id), new_appdata)
  }

  async delete() {
    await deleteDoc(doc(db, collectionName, this.id))
  }
}

<template>
    <v-sheet class="mb-3" elevation="6">
        <v-navigation-drawer expand-on-hover rail @update:rail="updateRail" rail-width="60" width="300" permanent location="left" style="border: none;" elevation="5">
            <v-list class="d-flex flex-column justify-space-between" style="height: 100%;">
              <div style="height: 100%; overflow-y: auto; overflow-x: hidden;">
                <span v-for="category in navContent">
                  <span v-for="item in category">
                    <router-link :to="item.path" style="text-decoration:none" @click="isDeploy = false" :class="highlight[item.path].class">
                      <v-list-item link :color="item.color" :active="$route.path == item.path" density="compact">
                        <template v-slot:prepend>
                          <v-icon :color="item.color">{{ item.icon }}</v-icon>
                        </template>
                        <v-list-item-title>
                          <h3 :class="$route.path != item.path ? 'text-secondary' : ('text-'+item.color)" v-text="item.title"></h3>
                        </v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </span>
                  <div class="my-2">
                    <v-divider></v-divider>
                  </div>
                </span>
              </div>
              <div>
                <div class="my-2">
                  <v-divider></v-divider>
                </div>
                <span>
                  <a @click.prevent.stop="logout" style="text-decoration:none">
                    <v-list-item link color="primary" density="compact">
                      <template v-slot:prepend>
                        <v-icon color="error">mdi-logout</v-icon>
                      </template>
                      <v-list-item-title class="text-error">Déconnexion</v-list-item-title>
                    </v-list-item>
                  </a>
                </span>
              </div>
            </v-list>
        </v-navigation-drawer>
    </v-sheet>
</template>

<script>
import navItems from "@/data/navItems.js";
import { getAuth,  } from '@firebase/auth'
import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/appdata'

import Team from '@/classes/Team'

export default {
    setup() {
      return {
        navItems,
        profileStore: useProfileStore(),
        appDataStore: useAppStore(),
        teams: [],
      }
    },
    data() {
        return {
            unsub: [],
            isOpen: true,
            isDeploy: false,
        }
    },
    async created() {
      this.unsub.push(await Team.listenAll(teams => {
        this.teams = teams.find(team => team.id === this.profileStore.profile.currentTeam)
      }))
    },
    beforeUnmount() {
      this.unsub.forEach(unsub => {
        if (typeof unsub === 'function') unsub()
      })
    },
    computed: {
      highlight() {
        let classes = {}
        navItems.forEach(cat => {          
          cat.forEach(item => {
            let itemRoute = this.$router.resolve({ path: item.path })
            
            let visible = true
            if(this.profileStore.roleHighlight){
              if(itemRoute.meta.accessRules.devNeeded) {
                visible = false
              }

              if(itemRoute.meta.accessRules.accountNeeded && !itemRoute.meta.accessRules.roleNeeded.includes('User')){
                visible = false
              }

              if(itemRoute.meta.accessRules.displayParameter && !this.profileStore.roleHighlight.accessPages[itemRoute.meta.accessRules.displayParameter]){
                visible = false
              }
            }

            if(visible) {
              classes[item.path] = {class : "opacity-100", visible : true}
            }else{
              classes[item.path] = {class : "opacity-10", visible : false}
            }
            
          })
        })

        return classes
      },
      navContent() {
        let nav = []
        
        if(!this.profileStore.profile || !this.appDataStore.appdata || !this.appDataStore.appdata.parameters || !this.appDataStore.appdata.parameters.roles || (!this.profileStore.team && this.profileStore.profile.role === 'User')) {
          return nav
        }

        let currentTeam = this.profileStore.team
        let currentRoles = []
        if (currentTeam && currentTeam.roles && currentTeam.roles[this.profileStore.profile.id]) {
          for(let roleId of currentTeam.roles[this.profileStore.profile.id]){
            if(this.appDataStore.appdata.parameters.roles.find(role => role.name === roleId)) {
              currentRoles.push(this.appDataStore.appdata.parameters.roles.find(role => role.name === roleId))
            }
          }
        }

        let displayParameters = {
          compagny: false,
          web: false,
          messageCenter : false,
          depot : false,
          buildings : false,
          tools : false,
          staff : false,
          trainings : false,
          offers : false,
          contracts : false,
          communications : false,
          bank : false,
          invoices : false,
          finance : false,
          data : false
        }

        if (this.profileStore.profile && this.profileStore.profile.role == 'User') {
          for (const key in displayParameters) {
            displayParameters[key] = false
            for (const role of currentRoles) {
              if (role.accessPages && role.accessPages[key]) {
                displayParameters[key] = true
              }
            }
          }
        }

        navItems.forEach(cat => {
          let items = []
          
          cat.forEach(item => {
            let itemRoute = this.$router.resolve({ path: item.path })
            
            let visible = true
            if(itemRoute.meta.accessRules.accountNeeded && !this.profileStore.profile) {
              visible = false
            }
            if(itemRoute.meta.accessRules.devNeeded) {
              visible = false
            }
            if(itemRoute.meta.accessRules.accountNeeded && !itemRoute.meta.accessRules.roleNeeded.includes('User')){
              visible = false
            }

            if(itemRoute.meta.accessRules.displayParameter && !displayParameters[itemRoute.meta.accessRules.displayParameter]){
              visible = false
            }

            if(this.profileStore.profile.role != 'User' && itemRoute.meta.accessRules.roleNeeded.includes('User')){
              visible = true
            }

            if(visible) {
              item.title = itemRoute.meta.title
              item.name = itemRoute.name
              items.push(item)
            }
          })

          if (items.length > 0) {
            nav.push(items)
          }
        })
        
        let currentRoute = this.$router.currentRoute.value
        let needRedirection = false
        if(currentRoute.meta.accessRules.accountNeeded && !this.profileStore.profile) {
          needRedirection = true
        }
        if(currentRoute.meta.accessRules.devNeeded && !this.profileStore.profile.dev) {
          needRedirection = true
        }
        if(currentRoute.meta.accessRules.accountNeeded && !currentRoute.meta.accessRules.roleNeeded.includes('User') && this.profileStore.profile.role == 'User'){
          needRedirection = true
        }
        if(this.profileStore.profile.role == 'User' && currentRoute.meta.accessRules.displayParameter && !displayParameters[currentRoute.meta.accessRules.displayParameter]){
          needRedirection = true
        }
        
        if(needRedirection) {
          this.$router.push('/home')
        }

        return nav
      }
    },
    methods: {
        updateRail(e) {
          this.isDeploy = !e;
        },
        logout(){
          getAuth().signOut()
          this.$router.push('/login')
        },
    },
}

</script>
export default appdata => {
  appdata.parameters = {
    version: null,    
    general: {
      access: {
        userCanAccess: true,
        autoOfferGrid: true,
      },
      finance: {
        initialCapital: 100000,
        initialTreasury: 100000,
        taxRates: true,
      },
      overdraft: {
        overdraftAuthorization: 50000,
        agiosRate: 5,
        placementRate: 3,
      },
      loans: {
        baseRate: 1,
        maxDuration: 60,
        fees: 1000,
        initial: false,
        initialValue: 0,
        initialDuration: 0,
        initialBaseRate: 0,
        earlyRepaymentPenaltyRate: 5
      },
      maxPaimentDelay: 120,
      suppliersPaimentDelay: 0,
      discountEffect: 2,
      bonusEffect: 2,
      firingCost: 25,
      fixFiringCost: 500,
      hiringCost: 0,
      fixHiringCost: 0,
      managerRatio: 10,
      maxOvertime : 44,
      maxFreeBonus: 0,
    },

    market: {
      marketEvolution: 10,
      marketMultiplier: 1,
      contracts: {
        coeffs: {
          price: 25,
          quality: 25,
          rse: 25,
          reputation: 25,
        },
        inertia: {
          price: 30,
          quality: 30,
          rse: 30,
          reputation: 30,
        }
      },
      rh: {
        coeffs: {
          salary: 25,
          qvct: 25,
          finances: 25,
          reputation: 25,
        },
        inertia: {
          salary: 30,
          qvct: 30,
          finances: 30,
          reputation: 30,
        }
      },
    },
    roles: [],
  }

  return appdata
}

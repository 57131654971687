<template>
  <span>
    <v-badge v-if="badge!=undefined && getIcon().icon != 'none'" class="mx-2" color="surface" :text-color="getIcon().color" :icon="getIcon().icon" offset-y="8">
      <v-avatar :size="size" :color="`hsl(${profile.color}deg,70%,70%)`">
        <v-img v-if="profile && profile.avatar" :src="url" alt="avatar" />
        <v-icon v-else>mdi-account-circle</v-icon>
      </v-avatar>
    </v-badge>
    <v-avatar v-else :size="size" :color="`hsl(${profile.color}deg,70%,70%)`">
        <v-img v-if="profile && profile.avatar" :src="url" alt="avatar" />
        <v-icon v-else>mdi-account-circle</v-icon>
      </v-avatar>
  </span>
</template>

<script>
import { getStorage, ref, getDownloadURL} from "firebase/storage"
import { useProfileStore } from '@/stores/profile'

export default {
  props: ['profile','size', 'badge'],
  data: () => ({
    url: null,
    profileStore: useProfileStore(),
  }),
  async created(){
    const fileRef = ref(getStorage(), "/assets/images/avatars/"+this.profile.avatar)
    getDownloadURL(fileRef)
    .then((url) => {
      this.url = url
    })
  },
  watch: {
    profile: {
      handler() {
        const fileRef = ref(getStorage(), "/assets/images/avatars/"+this.profile.avatar)
        getDownloadURL(fileRef)
        .then((url) => {
          this.url = url
        })
      },
      deep: true,
      imediate: true,
    }
  },
  methods: {
    getIcon(){
      let role = 'none'
      if(this.profile.role == 'SuperAdmin'){
        role = 'SuperAdmin'
      }else if(this.profile.organisations[this.profileStore.profile.currentOrganisation]){
      role = this.profile.organisations[this.profileStore.profile.currentOrganisation].role
      }

      if(this.profile.dev){
        return {
          icon : 'mdi-xml',
          color : 'error'
        }
      }else if(role == 'User'){
        return {
          icon : 'none',
          color : 'none'
        }
      }else if(role == 'SuperAdmin'){
        return {
          icon : 'mdi-crown-outline',
          color : 'info'
        }
      }else if(role == 'Admin'){
        return {
          icon : 'mdi-security',
          color : 'success'
        }
      }else{
        return {
          icon : 'none',
          color : 'none'
        }
      }
    },
  },
}
</script>

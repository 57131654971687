import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import getCollectionPath from '@/assets/functions/getCollectionPath'
import { useCollectionStore } from '@/stores/collections'

const db = getFirestore()
const collectionName = 'periods/'

function docToInstance(doc) {
  const data = doc.data()

  if (data && data.info) {
    return new Period(doc.id, data.info.month, data.info.year, data.info.dayAmount, data.info.name, data.timestamp, data.strategyAvailable, data.resultAvailable, data.index)
  }

  return data ? new Period(doc.id, data.month, data.year, data.dayAmount, data.name, data.timestamp, data.strategyAvailable, data.resultAvailable, data.index) : null
}

export default class Period {
  constructor(id, month, year, dayAmount, name, timestamp = 0, strategyAvailable = false, resultAvailable = false, index = 0) {
    this.id = id
    this.month = month
    this.year = year
    this.dayAmount = dayAmount
    this.name = name
    this.timestamp = timestamp
    this.strategyAvailable = strategyAvailable
    this.resultAvailable = resultAvailable
    this.index = index
  }

  static initOne() {
    const collections = useCollectionStore()
    const periods = collections.periods

    let tmp_month
    let tmp_year

    if (periods.length === 0) {
      tmp_month = 1
      tmp_year = new Date().getFullYear()
    } else {
      const lastPeriod = periods[periods.length-1]

      tmp_month = lastPeriod.month + 1
      tmp_year = lastPeriod.year
      if (tmp_month > 12) {
        tmp_month = 1
        tmp_year = tmp_year + 1
      }
    }

    const month = tmp_month
    const year = tmp_year
    const dayAmount = new Date(year, month, 0).getDate()
    const tmp_name = new Date(year, month, 0).toLocaleDateString('fr-FR', { month: 'long' })
    const name = tmp_name.charAt(0).toUpperCase() + tmp_name.slice(1)

    let timestamp = 0

    return new Period(null, month, year, dayAmount, name, timestamp, false, 0)
  }

  async save() {
    const new_period = {
      month: this.month,
      year: this.year,
      dayAmount: this.dayAmount,
      name: this.name,
      timestamp: this.timestamp,
      strategyAvailable: this.strategyAvailable,
      resultAvailable: this.resultAvailable,
      index: this.index
    }
    
    if (this.id) {
      await updateDoc(doc(db, await getCollectionPath(collectionName), this.id), new_period)
    } else {
      const res = await addDoc(collection(db, await getCollectionPath(collectionName)), new_period)
      this.id = res.id
    }
  }

  static async listenAll(callback) {
    onSnapshot(collection(db, await getCollectionPath(collectionName)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list.sort(Period.sortPeriods))
    })
  }

  static async listenById(id, callback) {
    return onSnapshot(doc(db, await getCollectionPath(collectionName), id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }

  static async getAll(){
    const documents = await getDocs(collection(db, await getCollectionPath(collectionName)))
    return documents.docs.map(docToInstance).sort(Period.sortPeriods)
  }

  static async getById(id) {
    const document = await getDoc(doc(db, await getCollectionPath(collectionName), id))
    return docToInstance(document)
  }


  async delete(){
      let documentRef = doc(db, await getCollectionPath(collectionName), this.id)
      deleteDoc(documentRef)
  }

  static sortPeriods(a, b) {
    if (a.year === b.year) {
      return a.month - b.month
    } else {
      return a.year - b.year
    }
  }
  isTheLast() {
    const collections = useCollectionStore()
    return (collections.allPeriods.length === 0) || (this.id === collections.allPeriods[collections.allPeriods.length-1].id)
  }
  isBefore(p) {
    if (this.year === p.year) {
      return this.month < p.month
    } else {
      return this.year < p.year
    }
  }
}

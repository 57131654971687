import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    alias: ['/Home'],
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'Dashboard',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Company',
    name: 'Company',
    component: () => import('@/views/Company.vue'),
    meta: {
      title: 'Mon Entreprise',
      displayNav: true,
      accessRules: {
        displayParameter: "compagny",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Web',
    name: 'Web',
    component: () => import('@/views/Web.vue'),
    meta: {
      title: 'Applications web',
      displayNav: true,
      accessRules: {
        displayParameter: "web",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/MessageCenter',
    name: 'MessageCenter',
    alias: ['/MessageCenter'],
    component: () => import('@/views/MessageCenter.vue'),
    meta: {
      title: 'Messagerie',
      displayNav: true,
      accessRules: {
        displayParameter: "messageCenter",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Depot',
    name: 'Depot',
    component: () => import('@/views/Depot.vue'),
    meta: {
      title: 'Zone de dépôt',
      displayNav: true,
      accessRules: {
        displayParameter: 'depot',
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Buildings',
    name: 'Buildings',
    alias: ['/Buildings'],
    component: () => import('@/views/Buildings.vue'),
    meta: {
      title: 'Locaux',
      displayNav: true,
      accessRules: {
        displayParameter: "buildings",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Stuffs',
    name: 'Stuffs',
    alias: ['/Stuffs'],
    component: () => import('@/views/Stuffs.vue'),
    meta: {
      title: 'Equipements',
      displayNav: true,
      accessRules: {
        displayParameter: "stuffs",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Tools',
    name: 'Tools',
    alias: ['/Tools'],
    component: () => import('@/views/Tools.vue'),
    meta: {
      title: 'Outils',
      displayNav: true,
      accessRules: {
        displayParameter: "tools",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Staff',
    name: 'Staff',
    component: () => import('@/views/Staff.vue'),
    meta: {
      title: 'Collaborateurs',
      displayNav: true,
      accessRules: {
        displayParameter: "staff",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Trainings',
    name: 'Trainings',
    component: () => import('@/views/Trainings.vue'),
    meta: {
      title: 'Formation',
      displayNav: true,
      accessRules: {
        displayParameter: "trainings",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Suppliers',
    name: 'Suppliers',
    component: () => import('@/views/Suppliers.vue'),
    meta: {
      title: 'Fournisseurs',
      displayNav: true,
      accessRules: {
        displayParameter: "suppliers",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Offers',
    name: 'Offers',
    component: () => import('@/views/Offers.vue'),
    meta: {
      title: 'Offres',
      displayNav: true,
      accessRules: {
        displayParameter: "offers",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Contracts',
    name: 'Contracts',
    component: () => import('@/views/Contracts.vue'),
    meta: {
      title: 'Contrats',
      displayNav: true,
      accessRules: {
        displayParameter: "contracts",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Communication',
    name: 'Communication',
    component: () => import('@/views/Communication.vue'),
    meta: {
      title: 'Communication',
      displayNav: true,
      accessRules: {
        displayParameter: "communications",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Bank',
    name: 'Bank',
    component: () => import('@/views/Bank.vue'),
    meta: {
      title: 'Banque',
      displayNav: true,
      accessRules: {
        displayParameter: "bank",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Invoices',
    name: 'Invoices',
    component: () => import('@/views/Invoices.vue'),
    meta: {
      title: 'Factures',
      displayNav: true,
      accessRules: {
        displayParameter: "invoices",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Finance',
    name: 'Finance',
    component: () => import('@/views/Finance.vue'),
    meta: {
      title: 'Comptabilité',
      displayNav: true,
      accessRules: {
        displayParameter: "finance",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Data',
    name: 'Data',
    component: () => import('@/views/Data.vue'),
    meta: {
      title: 'Données',
      displayNav: true,
      accessRules: {
        displayParameter: "data",
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['User', 'Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Parameters',
    name: 'Parameters',
    component: () => import('@/views/Parameters.vue'),
    meta: {
      title: 'Paramètres',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Teams',
    name: 'Teams',
    component: () => import('@/views/Teams.vue'),
    meta: {
      title: 'Équipes',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Assets',
    name: 'Assets',
    component: () => import('@/views/Assets.vue'),
    meta: {
      title: 'Composants',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/MarketConfig',
    name: 'MarketConfig',
    component: () => import('@/views/MarketConfig.vue'),
    meta: {
      title: 'Config marché',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      },
    }
  },
  // {
  //   path: '/Period',
  //   name: 'Period',
  //   component: () => import('@/views/old/Period.vue'),
  //   meta: {
  //     title: 'Séquences',
  //     displayNav: true,
  //     accessRules: {
  //       displayParameter: false,
  //       accountNeeded : true,
  //       devNeeded : false,
  //       roleNeeded : ['Admin', 'SuperAdmin'],
  //     }
  //   }
  // },
  {
    path: '/Event',
    name: 'Event',
    component: () => import('@/views/Event.vue'),
    meta: {
      title: 'Evenements',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/ResultBonus',
    name: 'ResultBonus',
    component: () => import('@/views/ResultBonus.vue'),
    meta: {
      title: 'Bonus/Malus',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/ScoreEditor',
    name: 'ScoreEditor',
    component: () => import('@/views/ScoreEditor.vue'),
    meta: {
      title: 'Editeur de score',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Results',
    name: 'Results',
    component: () => import('@/views/Results.vue'),
    meta: {
      title: 'Résultats',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['Admin', 'SuperAdmin'],
      }
    }
  },
  {
    path: '/Templates',
    name: 'Templates',
    component: () => import('@/views/Templates.vue'),
    meta: {
      title: 'Templates',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['SuperAdmin'],
      }
    }
  },
  {
    path: '/ChangeWorld',
    name: 'ChangeWorld',
    component: () => import('@/views/ChangeWorld.vue'),
    meta: {
      title: 'Changement de monde',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : false,
        roleNeeded : ['SuperAdmin'],
      }
    }
  },
  {
    path: '/Dev',
    name: 'Dev',
    component: () => import('@/views/Dev.vue'),
    meta: {
      title: 'Dev tools',
      displayNav: true,
      accessRules: {
        displayParameter: false,
        accountNeeded : true,
        devNeeded : true,
        roleNeeded : [],
      }
    }
  },
  {
    path: '/Login/:token?',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login',
      displayNav: false,
      accessRules: {
        displayParameter: false,
        accountNeeded : false,
        devNeeded : false,
        roleNeeded : [],
      }
    }
  },
  {
    path: '/NoCurrentApp',
    name: 'NoCurrentApp',
    component: () => import('@/views/errors/NoCurrentApp.vue'),
    meta: {
      title: 'NoCurrentApp',
      displayNav: false,
      accessRules: {
        displayParameter: false,
        accountNeeded : false,
        devNeeded : false,
        roleNeeded : [],
      }
    }
  },
  {
    path: '/BadVersion',
    name: 'BadVersion',
    component: () => import('@/views/errors/BadVersion.vue'),
    meta: {
      title: 'BadVersion',
      displayNav: false,
      accessRules: {
        displayParameter: false,
        accountNeeded : false,
        devNeeded : false,
        roleNeeded : [],
      }
    }
  },
  {
    path: '/NoCurrentTeam',
    name: 'NoCurrentTeam',
    component: () => import('@/views/errors/NoCurrentTeam.vue'),
    meta: {
      title: 'NoCurrentTeam',
      displayNav: false,
      accessRules: {
        displayParameter: false,
        accountNeeded : false,
        devNeeded : false,
        roleNeeded : [],
      }
    }
  },
  // {
  //   path: '/old',
  //   name: 'old',
  //   alias: ['/old'],
  //   component: () => import('@/views/Tools.old.vue'),
  //   meta: {
  //     title: 'Outils',
  //     displayNav: true,
  //     accessRules: {
  //       displayParameter: 'tools',
  //       accountNeeded : true,
  //       devNeeded : false,
  //       roleNeeded : ['User', 'Admin', 'SuperAdmin'],
  //     }
  //   }
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'pathMatch(.*)*',
    component: () => import('@/views/errors/Error404.vue'),
    meta: {
      title: 'Error404',
      displayNav: false,
      accessRules: {
        displayParameter: false,
        accountNeeded : false,
        devNeeded : false,
        roleNeeded : [],
      }
    }
  },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(to => {
  if (['Login'].includes(to.name)) {
    getAuth().signOut()
  }
})

router.afterEach((to, from) => {
	nextTick(() => {
		document.title = 'MFB - ' + (to.meta.title ? to.meta.title : to.name)
	})
})

export default router
